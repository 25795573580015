'use client';
import clsx from 'clsx';
import ErrorInput from 'core/ErrorInput';
import React, { forwardRef } from 'react';
import { FieldError } from 'react-hook-form';

import cn from './style.module.sass';

type TextFieldPropsType = {
    value?: string;
    className?: string;
    title: string;
    isError?: FieldError;
    type: string;
    name: string;
};

const TextField: React.FC<TextFieldPropsType> = forwardRef<HTMLInputElement, TextFieldPropsType>(
    ({ value, className, isError, title, type, name, ...rest }, ref) => (
        <label className={clsx(cn.wrapper, isError && cn.wrapperError, className)}>
            <span className={cn['textField__title']}>{title}</span>
            <input
                ref={ref}
                value={value}
                className={cn.textField}
                type={type}
                name={name}
                {...rest}
            />
            <ErrorInput message={isError?.message} />
        </label>
    )
);

TextField.displayName = 'TextField';
export default TextField;
