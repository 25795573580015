'use client';

import clsx from 'clsx';
import { getExternalLink } from 'core/BasicImage/basic-image.util';
import Image, { ImageProps } from 'next/image';

import cn from './style.module.sass';

type BasicImageProps = ImageProps & {
    className?: string;
    customObjectFit?: 'cover' | 'contain';
};

export default function BasicImage({
    className,
    alt,
    customObjectFit,
    src,
    ...props
}: BasicImageProps) {
    return (
        <Image
            src={getExternalLink(src as string)}
            alt={alt}
            className={clsx(className, {
                [cn.objectFitCover as string]: customObjectFit === 'cover',
                [cn.objectFitContain as string]: customObjectFit === 'contain',
            })}
            {...props}
        />
    );
}
