import clsx from 'clsx';
import { AnchorHTMLAttributes, ReactNode } from 'react';

type BasicButtonProps = AnchorHTMLAttributes<HTMLAnchorElement> & {
    children: ReactNode;
    className?: string | undefined;
    customClass?: string | undefined;
    onClick?: () => void;
};

export default function BasicButton({
    children,
    className,
    customClass,
    onClick,
}: BasicButtonProps) {
    return (
        <button className={clsx(className, customClass)} onClick={onClick}>
            {children}
        </button>
    );
}
