import cn from './style.module.sass';

type ModalProps = {
    closeModal: () => void;
};
export default function Modal({ closeModal }: ModalProps) {
    return (
        <div className={cn['modal-success']}>
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <span className={cn['modal-close']} onClick={closeModal}></span>
            <div className={cn['modal-container']}></div>
            <div className={cn['modal-content']}>
                <div className={cn['modal-title']}>Заявка отправлена</div>
                <div className={cn['modal-description']}>
                    Скоро с&nbsp;вами свяжется наш менеджер
                </div>
            </div>
        </div>
    );
}
