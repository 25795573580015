import cn from './style.module.sass';

type ErrorInputProps = {
    message?: string;
};
export default function ErrorInput({ message }: ErrorInputProps) {
    if (message) {
        return <div className={`${cn.formMessage} invalid`}>{message}</div>;
    }
    return <div className={`${cn.formMessage} valid`}>&nbsp;</div>;
}
