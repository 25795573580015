'use client';

import clsx from 'clsx';
import Link, { LinkProps } from 'next/link';
import { usePathname } from 'next/navigation';
import { AnchorHTMLAttributes, ReactNode } from 'react';

type BasicLinkProps = LinkProps &
    AnchorHTMLAttributes<HTMLAnchorElement> & {
        exact?: boolean;
        children: ReactNode;
        activeClassName?: string;
        className?: string | undefined;
    };

export default function BasicLink({
    href,
    exact,
    children,
    className,
    activeClassName,
    target,
    rel,
    onClick,
}: BasicLinkProps) {
    const pathname = usePathname();
    const isActive = exact ? pathname === href : pathname.startsWith(href);

    return (
        <Link
            href={href}
            target={target}
            rel={rel}
            className={clsx(className, { [activeClassName as string]: isActive })}
            onClick={onClick}
        >
            {children}
        </Link>
    );
}
