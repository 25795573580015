'use client';
import BasicImage from 'core/BasicImage';
import React, { forwardRef } from 'react';

import cn from './style.module.sass';

type CheckboxInputProps = {
    title: string;
    defaultChecked: boolean;
};

const CheckboxInput: React.FC<CheckboxInputProps> = forwardRef<
    HTMLInputElement,
    CheckboxInputProps
>(({ title, defaultChecked, ...rest }, ref) => (
    <label className={cn.checkboxLabel}>
        <input
            ref={ref}
            className={cn.checkboxInput}
            type="checkbox"
            value={title}
            defaultChecked={defaultChecked}
            {...rest}
        />
        <i className={cn.checkboxDecor}> </i>
        <BasicImage
            width={20}
            height={20}
            src="/images/svg/chexbox-click.svg"
            alt="checkbox"
            className={cn.customCheck}
        />
        <span>{title}</span>
    </label>
));

CheckboxInput.displayName = 'CheckboxInput';

export default CheckboxInput;
